.f-intro {
   margin: 30px 50px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.cont-intro {
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 50px;

   .img-intro {
      img {
         width: 550px;
         height: auto;
         box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
         border-radius: 5px;
      }
   }
   .text-intro {
      color: var(--color-text);
      width: 550px;
      padding-top: 30px;

      .tl-intro {
         font-family: UTM-Due;
         font-size: 30px;
         font-weight: bold;
      }
      .des-intro {
         padding: 10px 0px;
         font-family: Mont-Medium;
         font-size: 16px;
         text-align: justify;
      }
   }
}
.f-soAT {
   margin-left: 330px;
   margin-top: -175px;
}
.view-soAT {
   background-color: #f1f5f4;
   width: fit-content;
   display: flex;
   flex-direction: row;
   gap: 50px;
   padding: 10px 70px 20px;

   .item-soAT {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-text);
      width: 200px;
      justify-content: start;

      .num-soAT {
         font-size: 54px;
         font-family: Mont-Bold;
      }
      .text-soAT {
         font-size: 20px;
         font-family: UTM-Due;
         font-weight: bold;
         text-align: center;
      }
      .hl-num {
         color: #ffd800;
      }
   }
}

.f-linhVuc {
   margin: 10px 100px;
}
.text-linhVuc {
   font-family: Mont-Medium;
   font-size: 16px;
   color: var(--color-text);
   text-align: justify;
   padding: 0px 10px 20px;
}
.list-linhVuc {
   display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   gap: 50px;

   .item-linhVuc {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      img {
         width: 350px;
         height: auto;
      }
      .tl-linhVuc {
         font-family: UTM-Due;
         font-weight: bold;
         font-size: 20px;
         color: var(--color-text);
      }
   }
}

.f-final {
   background-position: center;
   background-size: cover;
   padding: 50px 10px;
   margin: 30px 0px;

   .text-final {
      font-family: Mont-Medium;
      font-size: 16px;
      color: var(--color-text);
      text-align: justify;
      padding: 0px 100px 50px;
   }
   img {
      width: 100%;
      height: auto;
   }
}
