.f-video {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 20px 10px;

   iframe {
      width: 80rem;
      height: 45rem;
   }
}

@media (max-width: 992px) {
   .f-video {
      iframe {
         width: 90%;
         height: 40rem;
      }
   }
}
