.wrapper {
   width: 100%;
   position: fixed;
   z-index: 1000;
}

.h-top {
   padding: 1px 50px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   gap: 20px;
   background-color: #ffffff;
   line-height: 1;
}

.m-logo {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 20px;
}
.searchBox {
   position: relative;
   display: flex;
   align-items: center;
}
.searchIcon {
   position: absolute;
   left: 10px;
   cursor: pointer;
   color: #3b3b3b;

   &:hover {
      color: var(--color-menu);
   }
}

.searchInput {
   padding: 8px 10px 8px 32px; // Đệm cho icon ở bên phải
   border: 2px solid #818181;
   border-radius: 5px;
   font-size: 16px;
   width: 220px;
   color: var(--color-title);
   transition: border-color 0.2s;
   font-family: Mont-Medium;

   &:focus {
      border-color: var(--primary);
      outline: none;
   }

   &:focus ~ .searchIcon {
      color: var(--color-menu);
   }
}

.inner {
   box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
   background-color: var(--primary);
}

.navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 5px 50px;
}

.navbarLogo img {
   height: 50px;
   width: auto;
   padding-top: 3px;
}

.m-contact {
   color: var(--color-title);
   font-size: 16px;
   font-family: Mont-Medium;
}

.navbarLinks {
   display: flex;
   gap: 25px;
   position: relative;
}

.menu-item {
   text-decoration: none;
   padding: 10px 0px;
   border-radius: 4px;
   transition: background 0.3s;
   color: var(--color-text-w);
   position: relative;
   font-size: 16px;
   font-family: Mont-Regular;
   cursor: pointer;

   &:hover {
      color: var(--color-menu);
   }
   &:hover .subMenu {
      display: block;
   }
   &.active {
      color: var(--color-menu);
   }
}

.line {
   position: absolute;
   top: 35px;
   height: 3px;
   width: -webkit-fill-available;
   background-color: var(--color-menu);
   display: none;
}

.menu-item.active .line {
   display: block;
   transition: all 0.2s ease;
}

.navbarToggle {
   display: none;
   flex-direction: column;
   cursor: pointer;
}

.icon {
   font-size: 35px;
}

.icon-close {
   color: var(--color-text-w);
}

.icon-open {
   color: var(--color-r);
}

.login-cart {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 20px;
   padding-right: 30px;
}
.notify {
   position: absolute;
   top: -6px;
   right: -8px;
   background-color: #ffd5a2;
   font-family: Mont-Regular;
   font-size: 10px;
   border-radius: 15px;
   width: 21px;
   height: 21px;
   text-align: center;
   padding-top: 3px;
}
.icon-cart {
   background-color: #ffffff;
   line-height: 1;
   border-radius: 20px;
   width: 40px;
   height: 40px;
   cursor: pointer;

   img {
      transform: translate(40%, 50%);
   }
}
.login-a {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 5px;
   color: var(--color-text-w);
   cursor: pointer;

   .login-text {
      font-size: 16px;
      font-family: Mont-Regular;
   }

   &:hover {
      color: var(--color-menu);
   }
}
.cart {
   position: relative;
   cursor: pointer;

   &:hover .icon-cart {
      background-color: var(--color-menu);
   }
   &:hover .notify {
      font-weight: 600;
      color: #970000;
      background-color: #f8e5cd;
   }
}
.languageSwitcher {
   display: flex;
   align-items: center;
   gap: 0.5rem;

   button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--color-text);

      &:hover {
         color: var(--color-menu);
      }
   }

   .flagIcon {
      width: auto;
      height: 20px;
   }
}

.arrow-menu {
   font-size: 12px;
   padding-left: 3px;
}

.subMenu {
   display: none;
   position: absolute;
   top: 100%;
   left: 0;
   background-color: white;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
   padding: 10px 0;
   z-index: 1000;
   border-radius: 5px;

   .subMenu-list {
      display: flex;
      flex-direction: column;
   }
   .line-subMenu {
      width: auto;
      height: 2px;
      background-color: #dadada;
      margin: 0px 20px;
   }
   .subMenu-item {
      padding: 8px 20px;
      white-space: nowrap;
      color: var(--color-text);
      text-decoration: none;
      font-family: Mont-Regular;

      &:hover {
         background-color: #f0f0f0;
      }
      &.active {
         color: var(--primary);
      }
   }

   &::before {
      content: '';
      border-width: 15px 11px;
      border-style: solid;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: -25px;
      left: 23px;
   }
   &::after {
      content: '';
      display: block;
      position: absolute;
      top: -21px;
      width: 88px;
      height: 30px;
   }
}

@media (max-width: 1130px) {
   .navbarLinks {
      position: absolute;
      width: 280px;
      top: 105px;
      right: 0;
      background-color: var(--color-title);
      flex-direction: column;
      align-items: start;
      box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 12%);
      gap: 0;
      padding: 10px 30px;
      display: none;
   }

   .navbarLinks.open {
      display: flex;
   }

   .navbarToggle {
      display: flex;
   }

   .languageSwitcher {
      margin-bottom: 15px;
   }

   .subMenu {
      position: relative;
      color: var(--color-text-w);
      background: none;
      border: none;
      box-shadow: none;

      .line-subMenu {
         display: none;
      }
      .subMenu-item {
         color: var(--color-text-w);

         &:hover {
            background: none;
         }
         &.active {
            color: var(--color-menu);
         }
      }
      &::before {
         display: none;
      }
      &::after {
         display: none;
      }
   }
}

@media (max-width: 650px) {
   .h-top {
      padding: 1px 10px;
   }
   .navbar {
      padding: 5px 10px;
   }
}

@media (max-width: 500px) {
   .searchInput {
      width: 150px;
   }
}
