.background-home {
   background-position: center;
   background-size: cover;
}
.bg-head {
   display: flex;
   flex-direction: row;
   gap: 20px;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   padding: 20px 0px 20px 0px;
}
.img-branch {
   width: 650px;

   img {
      width: 650px;
      height: auto;
   }
   .img-branchHome {
      position: relative;
      z-index: 2;
   }
   .bg-vector {
      position: absolute;
      z-index: 1;
      top: 240px;
      right: 10px;
   }
}
.bg-text {
   padding-left: 60px;
}
.tl-branch,
.tl-slogan {
   font-family: SVN;
   color: var(--color-text-w);
}
.tl-branch {
   font-size: 80px;
   padding-left: 15px;
}
.tl-slogan {
   font-size: 55px;
   padding-bottom: 100px;
}
.img-slogan {
   padding-bottom: 50px;
   img {
      width: 620px;
      height: auto;
   }
}
.kham-pha {
   font-size: 20px;
   color: var(--color-text-w);
   border: 3px solid var(--color-da);
   padding: 5px 20px;
   border-radius: 25px;
   margin-left: 5px;
   transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

   &:hover {
      color: var(--color-menu);
      animation: zoomFade 0.6s ease-in-out;
   }
}
.banner-event {
   img {
      width: 100%;
      height: auto;
   }
}
.banner-in {
   color: var(--color-title);
   font-family: Mont-Medium;
   font-size: 24px;
   background-color: var(--color-da);
   width: 100%;
   font-style: italic;
   text-align: center;
   padding: 10px 0px;
}
.body-intro {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   padding: 20px 0px;
}
.tl-heading {
   font-family: UTM-Due;
   font-size: 42px;
   color: var(--color-title);
   font-weight: 600;
}
.cont-intro {
   display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   gap: 80px;
}
.des-intro {
   font-family: Mont-Medium;
   font-size: 18px;
   color: var(--color-text);
   max-width: 550px;
   text-align: justify;
   font-style: italic;

   .des-part {
      padding: 5px 0px;
   }
}
.img-cont-intro {
   img {
      width: 550px;
      height: auto;
   }

   .cam-ket {
      font-family: UTM-Due;
      font-size: 30px;
      font-weight: 400;
      color: var(--color-text-w);
      background-color: var(--primary);
      padding: 8px 20px;
      width: fit-content;
      margin-top: -113px;
      position: relative;
      margin-left: -26px;
   }
}

.body-proList {
   padding-bottom: 20px;
}
.head-pro {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   gap: 20px;
   padding: 10px 50px;
}
.view-all {
   font-family: UTM-Due;
   color: var(--color-title);
   font-size: 22px;
   font-weight: 600;
   border-bottom: 2px solid var(--color-da);
   cursor: pointer;
   transition: color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;

   &:hover {
      border-color: var(--primary);
      animation: zoomFade 0.4s ease-in-out;
   }
}
.total-proList {
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 50px;
   flex-wrap: wrap;
   align-items: center;
   margin: 10px 30px;
}
.proList-item {
   cursor: pointer;
   width: 250px;
   height: 430px;
   overflow: hidden;
   box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);

   img {
      width: 250px;
      height: auto;
      object-fit: contain;
      transition: transform 0.4s;
   }
   .proList-cont {
      font-family: Mont-Regular;
      color: var(--color-text-w);
      width: 248px;
      padding: 7px 20px;
      position: relative;
      margin-top: -89px;
      margin-left: 1px;

      .proList-name {
         font-weight: bold;
         font-size: 18px;
         padding-bottom: 5px;
      }
      .proList-num {
         font-size: 16px;
      }
   }
   &:hover .proList-cont {
      background-color: #00000074;
      color: var(--color-menu);
   }
   &:hover img {
      transform: scale(1.1);
   }
}

.body-product {
   display: block;
   text-align: center;
   padding: 10px 0px;
   background-color: #f1f5f4;
}
.namePro-tab {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 30px;

   .namePro-item {
      font-family: UTM-Due;
      font-size: 28px;
      font-weight: bold;
      color: #a8a8a8;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
         color: var(--primary);
      }
      &.active {
         color: var(--primary);
         border-bottom: 2px solid var(--primary);
      }
   }
}
.proList-tab {
   min-height: 410px;
   overflow: hidden;
}

.proBranch-list {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   gap: 30px;
   padding: 20px 50px;

   .proBranch-item {
      font-family: UTM-Due;
      padding: 10px;
      width: 260px;
      height: 370px;
      box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
      background-color: #ffffff;
      border-radius: 5px;
      cursor: pointer;

      .pro-star {
         text-align: left;
         padding-left: 10px;
         img {
            height: 22px;
            width: auto;
         }
      }
      .proBranch-cont {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 5px;

         .proB-img {
            width: 230px;
            height: 190px;
            overflow: hidden;

            img {
               width: 230px;
               height: 190px;
               object-fit: contain;
               transition: transform 0.4s;
            }
         }
         .proB-tl {
            font-weight: bold;
            color: var(--color-text);
            font-size: 20px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
         }
         .proB-price {
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 10px;

            .price-old {
               color: var(--color-text);
               font-size: 16px;
               text-decoration: line-through;
            }
            .price-now {
               color: #db4444;
               font-size: 18px;
            }
         }
         .proB-act {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            .btn-pur {
               background-color: var(--primary);
               color: var(--color-text-w);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               border-radius: 20px;
               display: flex;
               flex-direction: row;
               align-items: center;
               gap: 10px;
               padding: 5px 15px;
               width: fit-content;
               cursor: pointer;
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

               img {
                  width: 18px;
                  height: auto;
               }

               .pur-tl {
                  font-size: 18px;
               }

               &:hover {
                  color: var(--color-menu);
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
            .btn-tym {
               background-color: #f1f5f4;
               width: fit-content;
               padding: 7px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 25px;
               color: var(--color-text);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
               cursor: pointer;

               .icon-tym {
                  font-size: 20px;
               }

               &:hover {
                  color: #c80000;
                  background-color: #ff8888;
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
         }
      }

      &:hover .proBranch-cont .proB-img img {
         transform: scale(1.05);
      }
   }
}

.body-daugia {
   padding: 0px 0px 20px;
}
.total-daugia {
   padding: 10px 0px;
}
.daugia-list {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: start;
   flex-wrap: wrap;
   gap: 50px;

   .daugia-item {
      background-color: #a8a8a8;
      height: 300px;
      width: 500px;
      box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
      border-radius: 5px;
      overflow: hidden;

      img {
         height: 300px;
         width: 500px;
         object-fit: cover;
      }
   }
}

.so-an-tuong {
   background-position: center;
   background-size: cover;
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 50px 100px;

   .tl-heading2 {
      font-family: UTM-Due;
      font-size: 42px;
      color: var(--color-text-w);
      font-weight: bold;
   }
   .cont-soAT {
      font-family: Mont-Regular;
      font-size: 16px;
      color: var(--color-text-w);
      text-align: justify;
      padding-bottom: 10px;
   }
   .view-soAT {
      background-color: #a00505;
      width: fit-content;
      display: flex;
      flex-direction: row;
      gap: 100px;
      padding: 10px 70px 20px;
      margin: 0 auto;

      .item-soAT {
         display: flex;
         flex-direction: column;
         align-items: center;
         color: var(--color-text-w);
         width: 200px;
         justify-content: start;

         .num-soAT {
            font-size: 54px;
            font-family: Mont-Bold;
         }
         .text-soAT {
            font-size: 16px;
            font-family: Mont-Medium;
            text-align: center;
         }
         .hl-num {
            color: #ffd800;
         }
      }
   }
}
.banner-denSam {
   img {
      width: 100%;
      height: auto;
   }
   .denSam-tl {
      text-align: right;
      padding-right: 50px;
      position: relative;
      top: -35px;
      color: var(--color-text-w);
      font-size: 16px;
      font-family: Mont-Regular;
      font-style: italic;
   }
}

.body-news {
   text-align: center;
   display: block;
   margin: 20px 0px;
}
.news-list {
   display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   gap: 50px;
   padding: 20px 0px;
}
.news-item {
   position: relative;
   margin-left: 35px;
   cursor: pointer;

   .news-frame {
      width: 595px;
      height: 340px;
      border: 2px solid #818181;
   }
   .news-cont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 30px;
      align-items: center;
      position: absolute;
      top: 20px;
      left: -35px;

      .news-img {
         img {
            width: 260px;
            height: 300px;
            object-fit: cover;
         }
      }
      .news-info {
         font-family: UTM-Due;
         max-width: 300px;
         display: flex;
         flex-direction: column;
         align-items: start;
         gap: 5px;

         .news-tag {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary);
            gap: 5px;
            font-size: 18px;

            .name-tag {
               text-transform: uppercase;
               font-weight: bold;
            }
         }
         .news-tl {
            font-size: 20px;
            color: var(--color-text);
            font-weight: bold;
            text-align: justify;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding-bottom: 5px;
         }
         .news-text {
            color: var(--color-text);
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: justify;
         }
         .news-line {
            width: 300px;
            height: 2px;
            background-color: #818181;
            margin: 15px 0px 5px 0px;
         }
         .news-date {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary);
            gap: 5px;
            font-size: 18px;
            padding: 5px 0px;

            .date-now {
               font-weight: bold;
            }
         }
      }
   }

   &:hover .news-frame {
      border-color: var(--color-menu);
   }
   &:hover .news-cont .news-info .news-tl {
      color: var(--color-menu);
   }
}
.btn-view {
   padding: 15px;
}
.btn-viewAll {
   font-size: 20px;
   color: var(--color-text);
   border: 3px solid var(--color-da);
   padding: 5px 20px;
   border-radius: 25px;
   font-family: UTM-Due;
   font-weight: bold;
   cursor: pointer;
   transition: color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;

   &:hover {
      color: var(--color-title);
      border-color: var(--primary);
   }
   &:active {
      animation: zoomFade 0.4s ease-in-out;
   }
}

.dang-ky {
   background-color: var(--primary);
   width: fit-content;
   display: flex;
   flex-direction: row;
   gap: 50px;
   padding: 20px 40px;
   position: relative;
   align-items: start;
   height: 230px;
   margin: 60px auto -60px;
   box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);

   .form-dk {
      font-family: UTM-Due;
      font-weight: bold;
      color: var(--color-text-w);
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: start;
      max-width: 600px;

      .dk-tl {
         font-size: 42px;
      }
      .dk-des {
         font-size: 18px;
         font-weight: 400;
         padding-bottom: 10px;
      }
      .dk-enter {
         font-size: 20px;
         display: flex;
         flex-direction: row;
         gap: 20px;
         align-items: center;
         background-color: #ffffff;
         border-radius: 40px;
         padding: 5px;
         width: fit-content;

         .email-enter {
            max-width: 300px;
            padding-left: 20px;
            color: var(--color-text);
            border: none;
            outline: none;

            &:focus {
               outline: none;
            }
         }
         .btn-dk {
            background-color: var(--color-da);
            color: var(--color-text);
            padding: 8px 25px;
            border-radius: 30px;
            cursor: pointer;
            transition: color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;

            &:hover {
               color: var(--color-text-w);
               background-color: var(--primary);
            }
            &:active {
               animation: zoomFade 0.4s ease-in-out;
            }
         }
      }
   }
   .img-sam {
      img {
         width: 500px;
         height: auto;
         position: relative;
         top: -111px;
      }
   }
}

@keyframes zoomFade {
   0% {
      transform: scale(1);
      opacity: 1;
   }
   50% {
      transform: scale(1.05);
      opacity: 0.5;
   }
   100% {
      transform: scale(1);
      opacity: 1;
   }
}

@media (min-width: 1500px) {
}

@media (max-width: 1380px) {
}

@media (max-width: 1350px) {
   .img-branch {
      width: 500px;

      .bg-vector {
         top: 215px;
         width: 500px;
         height: auto;
      }
   }
   .img-slogan {
      img {
         width: 500px;
      }
   }
}
@media (max-width: 1200px) {
   .img-cont-intro {
      img {
         width: 450px;
      }
      .cam-ket {
         font-size: 26px;
         margin-top: -101px;
      }
   }
   .des-intro {
      max-width: 450px;
   }
   .so-an-tuong {
      padding: 30px 50px;
   }
}
@media (max-width: 1080px) {
   .img-branch {
      width: 400px;

      .bg-vector {
         top: 196px;
         width: 400px;
      }
   }
   .img-slogan {
      img {
         width: 400px;
      }
   }
   .so-an-tuong {
      .view-soAT {
         gap: 50px;
         padding: 10px 30px 20px;
      }
   }
   .dang-ky {
      flex-direction: column-reverse;
      .img-sam {
         img {
            width: 290px;
            top: 109px;
            right: -282px;
         }
      }
   }
}

@media (max-width: 992px) {
   .cont-intro {
      flex-direction: column-reverse;
      align-items: center;
      gap: 30px;
   }
   .so-an-tuong {
      .view-soAT {
         flex-direction: column;
      }
   }
}

@media (max-width: 880px) {
   .bg-head {
      flex-direction: column-reverse;
      padding: 30px 0px;
   }
   .bg-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
   }
   .img-branch {
      img {
         height: 360px;
         width: 400px;
         object-fit: cover;
         object-position: right;
      }
      .bg-vector {
         display: none;
      }
   }
}

@media (max-width: 650px) {
   .news-item {
      margin-left: 0px;
      .news-frame {
         display: none;
      }
      .news-cont {
         position: relative;
         flex-direction: column;
         top: 0px;
         left: 0px;
         .news-img {
            img {
               border-radius: 5px;
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
            }
         }
      }
   }
   .dang-ky {
      .img-sam {
         img {
            width: 190px;
            top: 100px;
            right: -282px;
         }
      }
   }
}

@media (max-width: 525px) {
   .img-slogan {
      img {
         width: 350px;
      }
   }
   .img-branch {
      height: 290px;
      width: 350px;
      img {
         height: 290px;
         width: 350px;
      }
   }
   .des-intro {
      max-width: 350px;
      padding: 0px 10px;
   }
   .img-cont-intro {
      img {
         width: 350px;
      }
   }
   .daugia-list {
      .daugia-item {
         width: 350px;
         height: 250px;
      }
   }
   .dang-ky {
      .form-dk {
         .dk-enter {
            .email-enter {
               max-width: 160px;
            }
         }
      }
      .img-sam {
         img {
            display: none;
         }
         .cam-ket {
            font-size: 20px;
            margin-top: -83px;
         }
      }
   }
}
