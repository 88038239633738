.footer {
   background-size: cover;
   background-position: center;
   color: var(--color-text-w);
   font-size: 16px;
   font-family: Mont-Regular;
}
.info-body {
   display: flex;
   flex-direction: row;
   align-items: start;
   justify-content: space-between;
   gap: 30px;
   padding: 100px 50px;
   flex-wrap: wrap;
}
.info-ft,
.policy-ft,
.sp-guest,
.connect-ft {
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 15px;
}
.tl-info {
   font-size: 18px;
   font-weight: 600;
   padding-bottom: 10px;
}
.tl-info2 {
   font-size: 18px;
   font-weight: 600;
   text-transform: uppercase;
   padding-bottom: 10px;
}
.info-item {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.info-name {
   font-weight: 600;
}
.list-mxh {
   display: flex;
   flex-direction: row;
   gap: 30px;

   .icon-mxh {
      font-size: 30px;
      color: var(--color-text-w);

      &:hover {
         color: var(--color-menu);
      }
   }
}

.container {
}

@media (max-width: 650px) {
   .container {
   }
}
