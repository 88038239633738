@import 'normalize.scss';

@font-face {
   font-family: Roboto;
   src: url('../../assets/fonts/Roboto-Regular.ttf');
}
@font-face {
   font-family: Roboto-Bold;
   src: url('../../assets/fonts/Roboto-Bold.ttf');
}
@font-face {
   font-family: utm;
   src: url('../../assets/fonts/UTM HelvetIns.ttf');
}
@font-face {
   font-family: Mont-Regular;
   src: url('../../assets/fonts/Montserrat-Regular.ttf');
}
@font-face {
   font-family: Mont-Medium;
   src: url('../../assets/fonts/Montserrat-Medium.ttf');
}
@font-face {
   font-family: Mont-Bold;
   src: url('../../assets/fonts/Montserrat-Bold.ttf');
}
@font-face {
   font-family: SVN;
   src: url('../../assets/fonts/SVN-Fourth Bold.ttf');
}
@font-face {
   font-family: UTM-Due;
   src: url('../../assets/fonts/00082-UTM-Duepuntozero.ttf');
}

:root {
   --primary: #0c7735;
   --color-title: #003b17;
   --color-text: #000000;
   --color-menu: #fecb00;
   --color-x: #c11b1b;
   --color-r: #ff0000;
   --color-bg: #f1f1f1;
   --color-text-2: #696969;
   --color-text-3: #343434;
   --color-text-w: #ffffff;
   --color-da: #ffd5a2;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   font-size: 62.5%;
}

body {
   font-family: Roboto;
   font-size: 1.6rem;
   line-height: 1.5;
   text-rendering: optimizeSpeed;
}

/* width */
::-webkit-scrollbar {
   width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #b0b0b0;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: var(--primary);
   border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: var(--color-menu);
}

.page-head {
   background-color: var(--primary);
   padding: 25px 0px;
}

.page-tl {
   font-family: utm;
   font-weight: 400;
   font-size: 45px;
   color: #ffffff;
   padding: 10px 50px;
}

.page-des {
   font-family: Roboto;
   color: #ffffff;
   font-size: 18px;
   padding: 5px 50px 10px;
}

.background-page {
   background-position: center;
   background-size: cover;
   width: 100%;
}
.tl-subMenu {
   font-family: SVN;
   font-size: 50px;
   padding: 60px 50px;
   color: var(--color-text-w);
   text-align: center;
}

a {
   text-decoration: none;
}

.body-sp {
   max-width: 1200px;
   margin: 30px auto;
   font-family: Roboto;
   font-size: 16px;
   color: var(--color-text);

   img {
      padding-top: 10px;
      max-width: 1200px;
   }
}

p {
   text-align: justify;
}

ol {
   padding-left: 30px;
}

ul {
   padding-left: 30px;
   margin: 10px 0px;
}

.p-justify {
   text-align: justify;
   word-wrap: break-word;
}

@media (min-width: 992px) {
   .page-tl {
      padding: 0px 160px;
   }
   .page-des {
      padding: 5px 160px 10px;
   }
}

@media (max-width: 1230px) {
   .body-sp {
      width: 90vw;

      img {
         width: 90vw;
      }
   }
}
