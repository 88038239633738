.total-page {
   display: flex;
   flex-direction: row;
   align-items: start;
   gap: 30px;
   justify-content: left;
   padding: 30px;
}

.sidebar-pro {
   width: 300px;
   padding: 10px;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   border-right: 2px solid #eaeaea;
   border-radius: 2px;
}

.searchBox {
   position: relative;
   display: flex;
   align-items: center;
}
.searchIcon {
   position: absolute;
   left: 10px;
   cursor: pointer;
   color: #3b3b3b;

   &:hover {
      color: var(--color-menu);
   }
}

.searchInput {
   padding: 8px 10px 8px 32px; // Đệm cho icon ở bên phải
   border: 2px solid #818181;
   border-radius: 5px;
   font-size: 16px;
   width: 280px;
   color: var(--color-title);
   transition: border-color 0.2s;
   font-family: Mont-Medium;

   &:focus {
      border-color: var(--primary);
      outline: none;
   }

   &:focus ~ .searchIcon {
      color: var(--color-menu);
   }
}
.btn-search {
   font-family: Mont-Bold;
   font-size: 16px;
   background-color: var(--primary);
   padding: 7px 20px;
   width: 280px;
   border-radius: 30px;
   box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
   text-align: center;
   color: var(--color-text-w);
   cursor: pointer;
   transition: color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
   margin-bottom: 20px;

   &:active {
      animation: zoomFade 0.4s ease-in-out;
   }
}
.proList-tl {
   font-family: UTM-Due;
   font-weight: bold;
   font-size: 24px;
   color: var(--color-title);
}
.line-pro {
   width: 100%;
   height: 2px;
   background-color: #d1d1d1;
   border-radius: 2px;
}
.check-proList {
   margin-bottom: 10px;

   .check-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .check-box {
         width: 20px;
         height: 20px;
         -webkit-appearance: none;
         appearance: none;
         background-color: #fff;
         border: 2px solid #727272;
         border-radius: 4px;
         position: relative;
         cursor: pointer;

         &:checked {
            background-color: #008000;
         }

         /* Tạo dấu tích tùy chỉnh */
         &:checked::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 4px;
            width: 6px;
            height: 12px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
         }
      }

      .namePro-type {
         font-family: UTM-Due;
         font-size: 18px;
         color: var(--primary);
         font-weight: bold;
      }
   }
}

.total-price {
   width: 100%;
   margin: 0px auto 10px;

   .price-label {
      display: flex;
      justify-content: space-between;
      color: var(--primary);
      font-weight: bold;
      font-family: UTM-Due;
      font-size: 18px;
   }
   .price-range {
      width: 100%;
      margin-top: 10px;
      -webkit-appearance: none;
      appearance: none;
      background-color: #000;
      height: 4px;
      border-radius: 2px;
      cursor: pointer;
   }
}

.proDX-list {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   padding: 10px 0px;

   .pro-de-xuat {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      font-family: UTM-Due;
      cursor: pointer;

      .proDX-img {
         width: 120px;
         height: 95px;
         overflow: hidden;
         box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);

         img {
            width: 120px;
            height: 95px;
            object-fit: contain;
            transition: transform 0.4s;
         }
      }
      .proDX-cont {
         display: flex;
         flex-direction: column;
         gap: 3px;

         .proDX-tl {
            font-weight: bold;
            color: var(--color-text);
            font-size: 16px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
         }
         .proDX-price {
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: left;
            gap: 3px;

            .priceDX-old {
               color: var(--color-text);
               font-size: 16px;
               text-decoration: line-through;
            }
            .priceDX-now {
               color: var(--primary);
               font-size: 18px;
            }
         }
      }
      &:hover .proDX-img img {
         transform: scale(1.05);
      }
      &:hover .proDX-cont .proDX-price .priceDX-now {
         color: #db4444;
      }
   }
}

.proBranch-list {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   gap: 30px;

   .proBranch-item {
      font-family: UTM-Due;
      padding: 10px;
      width: 260px;
      height: 370px;
      box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
      background-color: #ffffff;
      border-radius: 5px;
      cursor: pointer;

      .pro-star {
         text-align: left;
         padding-left: 10px;
         img {
            height: 22px;
            width: auto;
         }
      }
      .proBranch-cont {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 5px;

         .proB-img {
            width: 230px;
            height: 190px;
            overflow: hidden;

            img {
               width: 230px;
               height: 190px;
               object-fit: contain;
               transition: transform 0.4s;
            }
         }
         .proB-tl {
            font-weight: bold;
            color: var(--color-text);
            font-size: 20px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
         }
         .proB-price {
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 10px;

            .price-old {
               color: var(--color-text);
               font-size: 16px;
               text-decoration: line-through;
            }
            .price-now {
               color: #db4444;
               font-size: 18px;
            }
         }
         .proB-act {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            .btn-pur {
               background-color: var(--primary);
               color: var(--color-text-w);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               border-radius: 20px;
               display: flex;
               flex-direction: row;
               align-items: center;
               gap: 10px;
               padding: 5px 15px;
               width: fit-content;
               cursor: pointer;
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

               img {
                  width: 18px;
                  height: auto;
               }

               .pur-tl {
                  font-size: 18px;
               }

               &:hover {
                  color: var(--color-menu);
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
            .btn-tym {
               background-color: #f1f5f4;
               width: fit-content;
               padding: 7px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 25px;
               color: var(--color-text);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
               cursor: pointer;

               .icon-tym {
                  font-size: 20px;
               }

               &:hover {
                  color: #c80000;
                  background-color: #ff8888;
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
         }
      }

      &:hover .proBranch-cont .proB-img img {
         transform: scale(1.05);
      }
   }
}

.pagination {
   display: flex;
   justify-content: center;
   margin-top: 30px;
   font-family: UTM-Due;

   .page-button {
      margin: 0 5px;
      width: 35px;
      height: 35px;
      border: 1px solid #808080;
      background-color: #f0f0f0;
      cursor: pointer;
      font-weight: bold;
      color: #000;
      font-size: 18px;

      &:hover {
         background-color: #52c852c4;
      }
      &.active {
         background-color: var(--primary);
         color: #fff;
      }
   }
}

@keyframes zoomFade {
   0% {
      transform: scale(1);
      opacity: 1;
   }
   50% {
      transform: scale(1.05);
      opacity: 0.5;
   }
   100% {
      transform: scale(1);
      opacity: 1;
   }
}
