.main-subpage {
   margin: 30px 100px;
}
.preview-sp {
   display: flex;
   flex-direction: row;
   gap: 50px;
   justify-content: center;
   flex-wrap: wrap;
}
.prePro-sp {
   display: flex;
   flex-direction: row;
   align-items: start;
   gap: 20px;

   .prePro-tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .prePro-item {
         height: 60px;
         width: 80px;
         background-color: #fff;
         overflow: hidden;
         box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
         border-radius: 5px;
         border: 2px solid transparent;
         cursor: pointer;

         img {
            height: 60px;
            width: auto;
            object-fit: cover;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
         }
         &.selected {
            border-color: #ffe044;
         }
      }
   }
   .prePro-view {
      width: 500px;
      height: 400px;
      background-color: #fff;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);

      img {
         object-fit: cover;
         min-height: auto;
         min-width: 400px;
         position: relative;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
      }
   }
}

.prePro-info {
   font-family: UTM-Due;
   display: flex;
   flex-direction: column;
   align-items: start;
   gap: 10px;
   width: 500px;

   .prePro-star {
      text-align: left;
      margin-bottom: -15px;

      img {
         height: 25px;
         width: auto;
      }
   }
   .prePro-tl {
      font-weight: bold;
      color: var(--color-text);
      font-size: 36px;
   }
   .prePro-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      .bg-iconSub {
         height: 40px;
         width: 40px;
         background-color: #c89d00;
         border-radius: 5px 0 0 5px;
         color: #fff;
         cursor: pointer;
         transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

         .icon-subtract {
            font-size: 22px;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
         }
         &:hover {
            background-color: #b89000;
         }
         &:active {
            animation: zoomFade 0.6s ease-in-out;
         }
      }
      .pro-num {
         font-family: Roboto-Bold;
         font-size: 18px;
         text-align: center;
         width: 100px;
         height: 40px;
         border: 2px solid hsla(0, 0%, 0%, 0.5);

         /* Ẩn mũi tên điều chỉnh của input */
         -moz-appearance: textfield; /* Firefox */
         appearance: textfield; /* Chrome, Safari, Edge */

         &::-webkit-outer-spin-button,
         &::-webkit-inner-spin-button {
            -webkit-appearance: none; /* Chrome, Safari, Edge */
            margin: 0;
         }

         &:focus {
            outline: none;
         }
      }

      .bg-iconAdd {
         height: 40px;
         width: 40px;
         background-color: #db4444;
         color: #fff;
         border-radius: 0 5px 5px 0;
         transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
         cursor: pointer;

         .icon-add {
            font-size: 22px;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
         }

         &:hover {
            background-color: #ca3f3f;
         }
         &:active {
            animation: zoomFade 0.6s ease-in-out;
         }
      }
   }
   .prePro-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .pricePre-now {
         color: var(--color-text);
         font-size: 36px;
         font-weight: bold;
      }
      .pricePre-old {
         color: var(--color-text);
         font-size: 22px;
         text-decoration: line-through;
      }
   }
   .prePro-act {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-top: 20px;

      .btn-pur {
         background-color: var(--primary);
         color: var(--color-text-w);
         box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
         border-radius: 20px;
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 10px;
         padding: 5px 35px;
         width: fit-content;
         cursor: pointer;
         transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

         .pur-tl {
            font-size: 22px;
         }
         &:hover {
            color: var(--color-menu);
         }
         &:active {
            animation: zoomFade 0.6s ease-in-out;
         }
      }
      .btn-tym {
         background-color: #f1f5f4;
         width: fit-content;
         padding: 7px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 25px;
         color: var(--color-text);
         box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
         transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
         cursor: pointer;

         .icon-tym {
            font-size: 22px;
         }
         &:hover {
            color: #c80000;
            background-color: #ff8888;
         }
         &:active {
            animation: zoomFade 0.6s ease-in-out;
         }
      }
   }
}

.pro-content {
   margin-top: 50px;
}
.tabList-headPro {
   font-family: UTM-Due;
   font-size: 22px;
   background-color: #f1f5f4;
   display: flex;
   flex-direction: row;
   gap: 5px;
   justify-content: left;
   border-radius: 50px;
   font-weight: bold;
   width: fit-content;

   .headPro-item {
      color: var(--color-text);
      border-radius: 50px;
      padding: 5px 25px;
      cursor: pointer;
      transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

      &:hover {
         background-color: var(--primary);
         color: var(--color-text-w);
      }
      &:active {
         animation: zoomFade 0.6s ease-in-out;
      }
      &.active {
         background-color: var(--primary);
         color: var(--color-text-w);
      }
   }
}

.content-viewPro {
   padding-top: 30px;
   min-height: 600px;
}
.headPro-tl {
   font-family: UTM-Due;
   font-weight: bold;
   font-size: 30px;
   color: var(--color-text);
   border-bottom: 3px solid #ffad33;
   width: fit-content;
}
.body-headPro {
   font-family: Mont-Medium;
   font-size: 18px;
   text-align: justify;
   color: var(--color-text);
   padding: 20px 0px;

   img {
      width: 100%;
      height: auto;
   }
}

.c-intro {
   .body-headPro {
      font-style: italic;
   }
}

.proBranch-list {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   gap: 30px;
   padding: 20px 0px;

   .proBranch-item {
      font-family: UTM-Due;
      padding: 10px;
      width: 260px;
      height: 370px;
      box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
      background-color: #ffffff;
      border-radius: 5px;
      cursor: pointer;

      .pro-star {
         text-align: left;
         padding-left: 10px;
         img {
            height: 22px;
            width: auto;
         }
      }
      .proBranch-cont {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 5px;

         .proB-img {
            width: 230px;
            height: 190px;
            overflow: hidden;

            img {
               width: 230px;
               height: 190px;
               object-fit: contain;
               transition: transform 0.4s;
            }
         }
         .proB-tl {
            font-weight: bold;
            color: var(--color-text);
            font-size: 20px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
         }
         .proB-price {
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 10px;

            .price-old {
               color: var(--color-text);
               font-size: 16px;
               text-decoration: line-through;
            }
            .price-now {
               color: #db4444;
               font-size: 18px;
            }
         }
         .proB-act {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            .btn-pur {
               background-color: var(--primary);
               color: var(--color-text-w);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               border-radius: 20px;
               display: flex;
               flex-direction: row;
               align-items: center;
               gap: 10px;
               padding: 5px 15px;
               width: fit-content;
               cursor: pointer;
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

               img {
                  width: 18px;
                  height: auto;
               }

               .pur-tl {
                  font-size: 18px;
               }

               &:hover {
                  color: var(--color-menu);
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
            .btn-tym {
               background-color: #f1f5f4;
               width: fit-content;
               padding: 7px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 25px;
               color: var(--color-text);
               box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 12%);
               transition: color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
               cursor: pointer;

               .icon-tym {
                  font-size: 20px;
               }

               &:hover {
                  color: #c80000;
                  background-color: #ff8888;
               }
               &:active {
                  animation: zoomFade 0.6s ease-in-out;
               }
            }
         }
      }

      &:hover .proBranch-cont .proB-img img {
         transform: scale(1.05);
      }
   }
}

@keyframes zoomFade {
   0% {
      transform: scale(1);
      opacity: 1;
   }
   50% {
      transform: scale(1.05);
      opacity: 0.5;
   }
   100% {
      transform: scale(1);
      opacity: 1;
   }
}
